import React from 'react';
import { PlatformTab, PlatformTabEmptyState } from 'docComponents';

const AndroidTab = () => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="android" pageType="guidelines" />
    </PlatformTab>
  );
};

export default AndroidTab;
